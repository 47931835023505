import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';

import logo from 'img/companyLogo/dashly.svg';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Dashly?</SubTitle>
      <p>
        Dashly is an extensive lead generation tool. You can use it to start a conversation with
        your visitors, as well as for analytics and user tracking. The LiveSession integration will
        help you understand your customers better and help the faster.
      </p>
      <SubTitle className="mt-5">How does it work?</SubTitle>
      <p>
        When somebody visits your website, LiveSession will send a new event to Dashly. The event is
        called <i>Session URL</i> and it comes with the <i>url</i> property. This property contains
        a direct link to the session recording.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy>
        {`<script>
__ls("getSessionURL", function (url, isNewSession) {
    dashly.track("Session URL", {
        url: url,
    });
});
</script>`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works you need to have Dashly and LiveSession installed on your
        website. If you haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Dashly integration',
  metaDescription: 'Send LiveSession recordings to your Dashly user events.',
  canonical: '/help/dashly-integration/',
  logoStyle: { width: 130 },
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
